import React from "react";
// import GitHubIcon from "@mui/icons-material/GitHub";   // logo has been removed from website
import Carousel from "react-material-ui-carousel";

const Projects = () => {
  // Scrapped svg below because logo has been removed from website
  // const figma_logo_svg = (
  //   <svg
  //     fill="#999999"
  //     width="40px"
  //     height="40px"
  //     viewBox="80 -80 256.00 360.00"
  //     id="Flat"
  //     xmlns="http://www.w3.org/2000/svg"
  //     stroke="#000000"
  //     stroke-width="0.00256"
  //   >
  //     <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
  //     <g
  //       id="SVGRepo_tracerCarrier"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //       stroke="#000000"
  //       stroke-width="0.512"
  //     ></g>
  //     <g id="SVGRepo_iconCarrier">
  //       {" "}
  //       <g opacity="0.2">
  //         {" "}
  //         <circle cx="162" cy="128" r="34"></circle>{" "}
  //         <path d="M94,94.001,128,94V26.001H94a34,34,0,0,0,0,68Z"></path>{" "}
  //         <path d="M60,195.99951a34,34,0,0,0,68,0V161.999L94,162A33.99971,33.99971,0,0,0,60,195.99951Z"></path>{" "}
  //       </g>{" "}
  //       <path d="M186.61523,94.00049A41.97934,41.97934,0,0,0,162,18.001H94A41.97934,41.97934,0,0,0,69.38477,94.00049a41.93894,41.93894,0,0,0,.00048,67.99939A41.9892,41.9892,0,1,0,136,195.99951V160.948a41.97356,41.97356,0,1,0,50.61523-66.94751ZM188,60.001a26.02916,26.02916,0,0,1-25.98047,25.99951L162,86l-.03857.001L136,86V34.001h26A26.02947,26.02947,0,0,1,188,60.001Zm-120,0a26.02947,26.02947,0,0,1,26-26h26V86H94l-.019.00049A26.02934,26.02934,0,0,1,68,60.001ZM68,128a26.02926,26.02926,0,0,1,25.981-25.99951L94,102.001l26-.00074V153.999L94,154A26.02947,26.02947,0,0,1,68,128Zm26,93.999A25.99951,25.99951,0,1,1,94,170l26-.00073v26.00024A26.02937,26.02937,0,0,1,94,221.999ZM162,154a25.99952,25.99952,0,0,1-.03857-51.999H162l.019-.00049A25.99976,25.99976,0,0,1,162,154Z"></path>{" "}
  //     </g>
  //   </svg>
  // );

  const data = [
    {
      title: "Cloud Snap",
      link: "https://github.com/squeasyatwork/cloud-snap",
      // logo: <GitHubIcon fontSize="xl"></GitHubIcon>,   // logo has been removed from website
      date: `Jun '23`,
      description: `Cloud application to upload/store/delete images with AWS and detect objects in them.
    • Cut down operation costs by 70% by going serverless using Python scripts and AWS Lambda
• Made development 2x faster, maintainable, and testable by using AWS API Gateway to design API
• Achieved blazing fast object detection using YOLO (You Only Look Once) technology
• Implemented end-to-end security using industry standard OAuth 2.0
    `,
    },
    {
      title: "Make It",
      link: "https://www.figma.com/proto/chnYWErBRMpDonzbFeazhj/5152-a3-prototype?node-id=35%3A124&scaling=scale-down&page-id=0%3A1&starting-point-node-id=35%3A124",
      // logo: figma_logo_svg,   // logo has been removed from website
      date: `Jan '23`,
      description: `Mobile app to host, book, and watch workshops on everything DIY.
• Offered client an interactable mockup made with Figma
• Achieved industry-grade user interface by applying UI/UX principles
• Performed iterative product design: requirements analysis → design artifacts → feedback
• Artifacts made: questionnaire, storyboard, user personas, hand-sketches
`,
    },
  ];
  return (
    <div id="projects">
      <div className="w-3/5 lg:w-1/3 border-t-2 border-slate-100 -mt-2 lg:mt-4 lg:pt-2 mx-auto lg:text-6xl text-4xl text-slate-500 font-thin text-center">
        Projects
      </div>
      <div className="bg-[#FFFFC8]/80 transition duration-400 ease-in hover:ease-in hover:shadow-md hover:shadow-gray-100 rounded-lg text-lg lg:text-2xl relative w-9/10 lg:w-3/5 mt-3 lg:mt-7 mb-20 lg:mb-24 mx-4 lg:mx-auto p-2 py-4">
        <Carousel
          autoPlay={false}
          navButtonsAlwaysVisible={true}
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              width: "2rem",
              height: "2rem",
              opacity: 0.2,
            },
          }}
          indicatorIconButtonProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              opacity: 0.5,
            },
          }}
        >
          {data.map((project) => {
            return (
              <div className="relative flex flex-col mx-4 space-y-1">
                <div className="flex items-start justify-between font-semibold text-slate-500 sm:text-2xl lg:text-3xl">
                  <div className="w-1/3 lg:w-3/5">
                    <a
                      className="absolute rounded-2xl p-2 hover:text-slate-600 shadow-md hover:shadow-lg hover:shadow-[#edb611]/30 transition duration-400 ease-in hover:ease-in"
                      href={project.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {project.title}{" "}
                    </a>
                  </div>
                  <div className="text-right sm:w-3/5 w-2/5">
                    <ul>
                      {project.date.split("\n").map((currentDate) => {
                        return <li>{currentDate}</li>;
                      })}
                    </ul>
                  </div>
                </div>
                <br></br>
                <div className="text-left">
                  <ul>
                    {project.description.split("\n").map((currentLine) => {
                      return <li>{currentLine}</li>;
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Projects;
